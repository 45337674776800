<template>
  <vx-card class="payment-gateway-menu-page"
           :class="{'main-box top-zero-radius md:w-1/2': !paymentGateway.id, 'no-box-shadow': paymentGateway.id > 0} "
           v-if="Object.keys(paymentGateway).length > 0">

    <profile-main-box :profile="getPayGatewayProfile"
                      :actions-list="actionsList"
                      @payment-gateway-turnover="showPaymentGatewayTurnoverPromptStatus = true"
                      @payment-gateway-receive="showPaymentGatewayReceivePromptStatus = true"/>

    <!--  show payment gateway turnover prompt  -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showPaymentGatewayTurnoverPromptStatus"
      @close="showPaymentGatewayTurnoverPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">

          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.paymentGateways.transactions.dynamicTitle', {paymentGateway: paymentGateway.title}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer flex">
<!--            <div class="mx-2" @click="handleClick('printPaymentGatewayTransactions')">-->
<!--              <custom-icon icon="PRINT"/>-->
<!--            </div>-->
            <div @click="showPaymentGatewayTurnoverPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <payment-gateway-transactions :payment-gateway-id="paymentGatewayId"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!--  show payment gateway received prompt  -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showPaymentGatewayReceivePromptStatus"
      @close="showPaymentGatewayReceivePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('receivePaymentGatewayBalanceBTN')">
              <custom-icon color="success"
                           icon="SAVE"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.paymentGateways.receive.dynamicTitle', {paymentGateway: paymentGateway.title}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showPaymentGatewayReceivePromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <received-payment-gateway-balance :payment-gateway-id="paymentGatewayId"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>
  </vx-card>
</template>

<script>
import axios from 'axios'
import ProfileMainBox from '../../../../../../components/profileMainBox/profileMainBox'
import {addComma} from '../../../../../../assets/js/functions'
import CustomIcon from '../../../../../../components/customIcon/customIcon'
import {getPaymentGateway} from '../../../../../../http/requests/paymentGateways'
import PaymentGatewayTransactions from '../transactions/paymentGatewayTransactions'
import ReceivedPaymentGatewayBalance from "../receive/receivedPaymentGatewayBalance";

export default {
  name: 'paymentGateway',
  components: {ReceivedPaymentGatewayBalance, PaymentGatewayTransactions, CustomIcon, ProfileMainBox},
  props: {
    paymentGatewayId: 0
  },
  data () {
    return {
      showPaymentGatewayTurnoverPromptStatus: false,
      showPaymentGatewayReceivePromptStatus: false,
      actions: {
        toolbar: [
          {
            id: {
              name: 'editPaymentGateway',
              params: {id: this.$route.params.id}
            },
            type: 'link',
            icon: 'icon-edit-2',
            iconPack: 'feather',
            color: 'warning',
            permission: 'payment_gateway.update'
          }
        ],
        leftToolbar: [
          {
            id: {name: 'paymentGateways'},
            type: 'link',
            icon: 'icon-chevron-left',
            iconPack: 'feather'
          }
        ]
      },
      paymentGateway: {},
      actionsList: [
        {
          event: 'payment-gateway-turnover',
          i18n: 'treasury.paymentGateways.paymentGateway.actions.transactions',
          icon: 'icon-layers',
          iconPack: 'feather'
        },
        {
          event: 'payment-gateway-receive',
          i18n: 'treasury.paymentGateways.paymentGateway.actions.receive',
          icon: 'icon-dollar-sign',
          iconPack: 'feather'
        }
      ]
    }
  },
  computed: {
    getPayGatewayProfile () {
      const paymentGateway = {
        avatar: this.paymentGateway.image ? this.paymentGateway.image : '',
        name: !this.paymentGateway ? this.paymentGateway.title : '',
        importantData: [],
        description: this.paymentGateway.description && this.paymentGateway.description.length > 1 ? this.paymentGateway.description : null
      }

      paymentGateway.importantData.push({
        key: this.$t('treasury.paymentGateways.paymentGateway.importantData.type'),
        value: this.paymentGateway.type && this.paymentGateway.type === 1 ? this.$t('treasury.paymentGateways.types.online') : this.$t('treasury.paymentGateways.types.pos')
      })

      paymentGateway.importantData.push({
        key: this.$t('treasury.paymentGateways.paymentGateway.importantData.bank'),
        value: this.paymentGateway.bank ? this.paymentGateway.bank.name : '-',
        valueClass: 'text-primary'
      })

      paymentGateway.importantData.push({
        key: this.$t('treasury.paymentGateways.paymentGateway.importantData.balance'),
        value: addComma(this.paymentGateway.balance) || '0',
        valueClass: this.paymentGateway.balance >= 0 ? 'text-success' : 'text-danger '
      })

      return paymentGateway
    }
  },
  created () {
    /*setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
    }, 50)*/

    this.getPaymentGateway()
  },
  methods: {
    getPaymentGateway () {
      getPaymentGateway(this.paymentGatewayId).then(response => {

        this.paymentGateway = response.data.data
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  },
  watch: {
    '$store.state.helper.paymentGatewaysChanged': {
      handler (val) {
        if (val) {
          this.paymentGateway = {}
          this.getPaymentGateway()
        }
      }
    }
  }
}
</script>

<style lang="scss">
.payment-gateway-menu-page {

  .vx-card__body {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
</style>
