<template>
  <div class="profile-main-box"
       v-if="profile && Object.keys(profile).length > 0">
    <div class="profile-box">
      <vs-row>
        <div @click="imagePreviewStatus = true"
             class="mx-auto">
          <lazy-image class="profile-avatar cursor-pointer"
                      :src="profile.avatar"
                      :alt="profile.name"/>
        </div>

        <image-preview v-if="imagePreviewStatus"
                       :image="{src: profile.avatar, alt: profile.name}"
                       @closed="imagePreviewStatus = false"/>
      </vs-row>

      <vs-row>
        <div class="profile-name w-auto">
          {{ profile.name }}
        </div>
      </vs-row>

      <vs-row v-for="(detail, detail_index) in profile.details"
              :key="detail_index">

        <div class="profile-details w-auto mx-auto mt-2">

          <vs-icon v-if="detail.icon"
                   class="w-auto inline-block mr-1"
                   :icon="detail.icon"
                   :icon-pack="detail.iconPack"/>

          <div class="w-auto inline-block">
            {{ `${detail.key} ${detail.key ? ':' : ''}` }}
            <span class="detail-content" :style="detail.style || ''">
              {{ detail.value }}
            </span>
          </div>
        </div>
      </vs-row>

      <vs-row class="important-data-list">
        <vs-col class="important-data-item"
                :class="important_data.class || ''"
                v-for="(important_data, important_data_index) in profile.importantData"
                :key="important_data_index">
          <vs-row>
            <div class="key w-full useral-font-weight-medium">
              {{ important_data.key }}
            </div>

            <div v-if="important_data.hasOwnProperty('type') && important_data.type === 'price'"
                 class="value w-full mt-1"
                 :class="important_data.valueClass">
              {{ addComma(important_data.value) }}
            </div>

            <div v-else
                 class="value w-full mt-1"
                 :class="important_data.valueClass">
              {{ important_data.value }}
            </div>

          </vs-row>
        </vs-col>
      </vs-row>
    </div>

    <div class="actions-list-box">
      <ul class="actions-list">
        <router-link :to="{name: action.name, params: action.params || {}, query: action.query || {}}"
                     v-for="(action, action_index) in actionsList"
                     :key="action_index">

          <li class="action" @click="action.hasOwnProperty('event') ? $emit(action.event) : ''">
            <vs-row>
              <vs-icon class="w-1/12"
                       :class="[action.color ? `text-${action.color}`:'']"
                       :icon="action.icon"
                       :icon-pack="action.iconPack"/>

              <div class="action-label w-auto"
                   :class="[action.color ? `text-${action.color}`:'']">
                {{ $t(action.i18n) || action.header }}
              </div>

              <vs-spacer/>

              <div v-if="action.badge"
                   class="action-badge-box">
                <div class="action-badge"
                     :class="[action.badge.type !== 'icon' ? `badge-${action.badge.color || 'primary'}`: '']">

                  <vs-icon v-if="action.badge.type === 'icon'"
                           :class="[action.color ? `text-${action.color}`:'']"
                           :icon="action.icon"
                           :icon-pack="action.iconPack"/>

                  <div v-else>
                    {{ action.badge.data }}
                  </div>

                </div>
              </div>

              <vs-icon :icon="$vs.rtl ? 'icon-chevron-left' : 'icon-chevron-right'"
                       iconPack="feather"/>
            </vs-row>
          </li>
        </router-link>
      </ul>
    </div>

    <div class="description" v-if="profile.description">
      <p>{{ profile.description }}</p>
    </div>
  </div>
</template>

<script>
import LazyImage from '@/components/lazyImage/lazyImage'
import ImagePreview from '@/components/imagePreview/imagePreview'

export default {
  name: 'profileMainBox',
  components: {
    ImagePreview,
    LazyImage
  },
  props: {
    profile: {
      type: Object,
      default: () => {
        return {}
      }
    },
    actionsList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      imagePreviewStatus: false
    }
  },
  methods: {
    addComma(val) {
      let sign = ''

      if (val < 0) sign = '-'

      val = val.toString()

      if (val[0] === '0') val = val.substr(1, val.length - 1)

      return val.toString().replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + sign
    }
  }
}
</script>

<style lang="scss">
.profile-main-box {
  padding: 10px 0;

  .vx-card__body {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .profile-box {
    position: relative;
    padding-bottom: 60px;
    margin-bottom: 60px;
    border-bottom: 1px solid #333333;

    .profile-avatar {
      border-radius: 100%;
      width: 95px;
      height: 95px;
    }

    .profile-name {
      font-size: 15px;
      font-weight: 700;
      margin: 20px auto 0;
    }

    .important-data-list {
      position: absolute;
      height: 80px;
      width: 70% !important;
      left: 0;
      right: 0;
      bottom: -40px;
      margin: auto;
      background: #131314;
      border: 1px solid #333333;
      border-radius: .5rem;
      box-shadow: 0px 4px 10px -3px black;
      flex-direction: column;

      @media (max-width: 767px) {
        width: 85% !important;
      }

      .important-data-item {
        text-align: center;
        display: flex;
        align-items: center;
        flex-grow: 1;
        width: auto !important;
        height: 100%;

        .key {
          color: #999999;
        }

        .value {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }

  .actions-list-box {
    width: 70% !important;
    margin: auto;

    @media (max-width: 767px) {
      width: 85% !important;
    }

    .actions-list {

      a {


        &:last-child {

          li {
            margin-bottom: 0;
          }
        }

        li.action {
          line-height: 50px;
          align-items: center;
          transition: all .3s ease;
          font-weight: 500;
          color: #ffffff;
          border: 1px solid #333333;
          border-radius: .5rem;
          padding: 0 10px;
          margin-bottom: 10px;

          &:hover {
            transform: translateX(5px);
            transition: all .3s ease;
          }

          i {
            display: flex;
            align-items: center;

            &.icon-chevron-left {
              font-size: 17px;
              font-weight: 700;
            }
          }

          .action-badge-box {
            display: flex;
            align-items: center;

            .action-badge {
              width: 25px;
              height: 25px;
              line-height: 25px;
              border-radius: 100%;
              text-align: center;
              margin-right: 10px;
              color: #FFFFFF;

              &.badge- {
                &primary {
                  background: rgba(31, 116, 255, 1);
                }

                &success {
                  background: rgba(70, 201, 58, 1);
                }

                &danger {
                  background: rgba(255, 71, 87, 1);
                }

                &warning {
                  background: rgba(255, 186, 0, 1);
                }

                &light {
                  background: rgba(245, 245, 245, 1);
                }

                &dark {
                  background: rgba(30, 30, 0, 1);
                }
              }
            }
          }
        }
      }
    }
  }

  .description {
    width: 70% !important;
    padding: 10px;
    margin: auto;
    line-height: 25px;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: .5rem;
    box-shadow: 0px 4px 10px -3px cecece;

    @media (max-width: 767px) {
      width: 85% !important;
    }

    p {
      text-align: justify;
    }
  }
}
</style>
